<template lang="">
  <div>
    <div class="container">
      <div class="title-flatform">
        <div class="title-content">
          <h1>{{ $t(`Home['download_mosappNow']`) }}</h1>
          <p>{{ $t(`Home['mosappEngageConversations']`) }}</p>
        </div>
      </div>
      <el-row :gutter="24">
        <el-col :xs="24" :sm="12" :md="12" :lg="6">
          <div class="downMosApp">
            <div class="num-secur-conversation">
              <img src="../../assets/image/home/flexibility.png" alt="" />
            </div>
            <div>
              <h1>{{ $t(`Home['flexibility_adaptability']`) }}</h1>
              <p>{{ $t(`Home['youCanTailorConversation']`) }}</p>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="6">
          <div class="downMosApp">
            <div class="num-secur-conversation1">
              <img src="../../assets/image/home/effective.png" alt="" />
            </div>
            <div>
              <h1>{{ $t(`Home['effectiveCommunication']`) }}</h1>
              <p>{{ $t(`Home['knowBeInformed']`) }}</p>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="6">
          <div class="downMosApp">
            <div class="num-secur-conversation2">
              <img src="../../assets/image/home/sharing.png" alt="" />
            </div>
            <div>
              <h1>{{ $t(`Home['sahringThoughts']`) }}</h1>
              <p>{{ $t(`Home['youCanExpressCommunicate']`) }}</p>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="6">
          <div class="downMosApp">
            <div class="num-secur-conversation3">
              <img src="../../assets/image/home/building.png" alt="" />
            </div>
            <div>
              <h1>{{ $t(`Home['buildingStrongConversation']`) }}</h1>
              <p>{{ $t(`Home['youCanShareInformation']`) }}</p>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div style="background-color: #E7EEFF;">
      <div class="container">
        <div class="downMos-app-already">
          <div class="donw-app-title">
            <h1 style="font-size:50px; font-weight:700;">{{ $t(`Layout['download_mosappAlready']`) }}</h1>
            <p style="margin-top: 20px;">{{ $t(`Home['keepMosAppEngage']`) }}</p><br /><br />
          </div>
          <div>
            <div style="display: flex; column-gap: 20px; flex-wrap: wrap">
                <div class="downMosApp-already">
                  <img src="../../assets/image/home/clears.png" alt="" />
                  <h1>{{ $t(`Home['clearCommuncation']`) }}</h1>
                </div>
                <div class="downMosApp-already">
                  <img src="../../assets/image/home/make.png" alt="" />
                  <h1>{{ $t(`Home['makeYourOwnCircle']`) }}</h1>
                </div>
                <div class="downMosApp-already">
                  <img src="../../assets/image/home/keep.png" alt="" />
                  <h1>{{ $t(`Home['keepCloser']`) }}</h1>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
}
</script>
<style lang="less" scoped>
.container {
  .title-flatform {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    margin-top: 40px;
    h1 {
      font-size: 34px;
      font-weight: 500;
    }
    .title-content {
        width: 835px;
        text-align: center;
        p {
          font-size: 20px;
          color:#595959;
          margin-top: 15px;
        }
    }
  }
  .downMosApp {
    height: 400px;
    margin-top: 40px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    img {
      border: 2px solid #3370FF;
      border-radius: 50%;
      padding: 10px;
      box-shadow: 7px 11px 15px -4px gray;
      cursor: pointer;
      width: 70%;
    }
    h1 {
      font-size: 20px;
      font-weight: 500;
    }
    p {
      font-size: 17px;
      color:#595959;
    }
  }
  .num-secur-conversation {
    position: relative;
    width: 340px;
    @media screen and (max-width: 768px) {
      width: 390px;
    }
  }
  .num-secur-conversation1 {
    position: relative;
    width: 340px;
    @media screen and (max-width: 768px) {
      width: 390px;
    }
  }
  .num-secur-conversation2 {
    position: relative;
    width: 340px;
    @media screen and (max-width: 768px) {
      width: 390px;
    }
  }
  .num-secur-conversation3 {
    position: relative;
    width: 340px;
    @media screen and (max-width: 768px) {
      width: 390px;
    }
  }
  .num-secur-conversation:before {
    content: '01';
    background-color: #3370FF;
    border-radius: 50%;
    position: absolute;
    color: #fff;
    height: 55px;
    width: 55px;
    line-height: 55px;
    bottom: 40px;
    right: 50px;
  }
  .num-secur-conversation1:before {
    content: '02';
    background-color: #3370FF;
    border-radius: 50%;
    position: absolute;
    color: #fff;
    height: 55px;
    width: 55px;
    line-height: 55px;
    bottom: 40px;
    right: 50px;
  }
  .num-secur-conversation2:before {
    content: '03';
    background-color: #3370FF;
    border-radius: 50%;
    position: absolute;
    color: #fff;
    height: 55px;
    width: 55px;
    line-height: 55px;
    bottom: 40px;
    right: 50px;
  }
  .num-secur-conversation3:before {
    content: '04';
    background-color: #3370FF;
    border-radius: 50%;
    position: absolute;
    color: #fff;
    height: 40px;
    height: 55px;
    width: 55px;
    line-height: 55px;
    bottom: 40px;
    right: 50px;
  }
  .downMos-app-already {
    display: flex;
    margin-top: 40px;
    padding: 40px 0 60px;
    justify-content: space-between;
    @media screen and (max-width: 500px) {
      display: inherit;
      margin-top: 40px;
    }
    p {
      color:#595959;
    }
  }
  .donw-app-title {
    width: 460px;
    @media screen and (max-width: 500px) {
      width: auto;
    }
  }
  .downMosApp-already {
    width: 235px;
    @media screen and (min-width: 1650px) {
      width: 315px;
    }
    @media screen and (max-width: 630px) {
      width: 100%;
      height: auto;
      margin-bottom: 30px;
    }
    img {
      width: 100%;
      height: 360px;
      border-radius: 10px;
      margin-bottom: 15px;
    }
    h1 {
      font-size: 20px;
      font-weight: 500;
    }
  }
}
</style>
